import styles from "shared/components/Image.module.less";

import classNames from "classnames";
import { MouseEventHandler } from "react";

interface Props {
  className: string;
  src: string;
  alt: string;
  onClick?: MouseEventHandler | undefined;
}

function Image({ className, src, alt, onClick = undefined }: Props) {
  return (
    <img
      className={classNames(styles.img, className)}
      src={src}
      alt={alt}
      onClick={onClick}
      loading="lazy"
    />
  );
}

export default Image;
