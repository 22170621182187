// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cQlyFavcmY5qJT3CfJgO {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/tickets/TicketsPage.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cQlyFavcmY5qJT3CfJgO"
};
export default ___CSS_LOADER_EXPORT___;
