import styles from "./EventCard.module.less";

import { Link } from "react-router-dom";
import Image from "shared/components/Image";

interface Props {
  id: string;
}

const EventCard = ({ id }: Props) => {
  const photo = `${process.env.PUBLIC_URL}/img/archive/${id}-1.jpg`;
  return (
    <Link className={styles.link} to={`/archive/event/${id}`}>
      <Image className={styles.photo} src={photo} alt={photo} />
    </Link>
  );
};

export default EventCard;
