// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zyenNigN1aaVo5SbhDA8 {\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  color: var(--background-color);\n  font-size: 1.5em;\n  border-bottom: 2px solid transparent;\n}\n.zyenNigN1aaVo5SbhDA8:hover,\n.zyenNigN1aaVo5SbhDA8.rEYdZ7OEMwzV6ZLGmTb6 {\n  text-decoration: none;\n  border-bottom: 2px solid var(--background-color);\n}\n.zyenNigN1aaVo5SbhDA8.WwI_BWgzQCW90pjxS1wr {\n  color: var(--foreground-color);\n}\n.zyenNigN1aaVo5SbhDA8.WwI_BWgzQCW90pjxS1wr:hover,\n.zyenNigN1aaVo5SbhDA8.WwI_BWgzQCW90pjxS1wr.rEYdZ7OEMwzV6ZLGmTb6 {\n  border-bottom: 2px solid var(--foreground-color);\n}\n", "",{"version":3,"sources":["webpack://./src/shared/components/HeaderLink.module.less"],"names":[],"mappings":"AAEA;EACE,qDAAA;EACA,8BAAA;EACA,gBAAA;EACA,oCAAA;AADF;AAGE;;EAEE,qBAAA;EACA,gDAAA;AADJ;AAIE;EACE,8BAAA;AAFJ;AAKE;;EAEE,gDAAA;AAHJ","sourcesContent":["@import \"app/styles/const.less\";\n\n.headerLink {\n  font-family: @primaryFontName;\n  color: var(--background-color);\n  font-size: 1.5em;\n  border-bottom: 2px solid transparent;\n\n  &:hover,\n  &.active {\n    text-decoration: none;\n    border-bottom: 2px solid var(--background-color);\n  }\n\n  &.dark {\n    color: var(--foreground-color);\n  }\n\n  &.dark:hover,\n  &.dark.active {\n    border-bottom: 2px solid var(--foreground-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerLink": "zyenNigN1aaVo5SbhDA8",
	"active": "rEYdZ7OEMwzV6ZLGmTb6",
	"dark": "WwI_BWgzQCW90pjxS1wr"
};
export default ___CSS_LOADER_EXPORT___;
