import styles from "./EventItem.module.less";
import { EventItemType } from "api/ticketsApi";
import classNames from "classnames";
import { useCallback } from "react";
import { backendUrl } from "app/const";
import { useAppDispatch, useAppSelector } from "store";
import { processAddToBasket } from "processes/basket/addToBasketProcess";
import { selectBasketItem, selectBasketPending } from "../selectors";
import { processDeleteFromBasket } from "processes/basket/deleteFromBasketProcess";
import Loader from "shared/components/Loader";

interface Props {
  item: EventItemType;
}

export const EventItem = ({ item }: Props) => {
  const dispatch = useAppDispatch();
  const basketItem = useAppSelector((state) =>
    selectBasketItem(state, item.id)
  );
  const basketPending = useAppSelector(selectBasketPending);

  const handleAddToBasket = useCallback(
    () => dispatch(processAddToBasket(item.id)),
    [dispatch, item]
  );

  const handleDelFromBasket = useCallback(
    () => dispatch(processDeleteFromBasket(item.id)),
    [dispatch, item]
  );

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${backendUrl + item.imgThumb})` }}
    >
      <div className={classNames(styles.item, styles.withImg)}>
        <div
          className={styles.mobileName}
          dangerouslySetInnerHTML={{ __html: item.name }}
        />
        <div className={styles.logo}>
          <img className={styles.img} src={backendUrl + item.imgCover} alt="" />

          {!basketItem && (
            <button className={styles.btn} onClick={handleAddToBasket}>
              {!basketPending ? `Купить за ${item.price} руб` : <Loader />}
            </button>
          )}

          {basketItem && (
            <div className={styles.inBasket}>
              <button
                className={classNames(styles.btn, styles.inBasketBtn)}
                onClick={handleDelFromBasket}
                disabled={basketPending}
              >
                {!basketPending ? "–" : <Loader />}
              </button>
              <div className={styles.inBasketText}>
                В корзине {basketItem.count} шт
              </div>
              <button
                className={classNames(styles.btn, styles.inBasketBtn)}
                onClick={handleAddToBasket}
                disabled={basketPending}
              >
                {!basketPending ? "+" : <Loader />}
              </button>
            </div>
          )}

          <div className={styles.priceContainer}>
            <span className={styles.price}>{item.price} руб</span>
            {item.futurePrice && (
              <span className={styles.futurePrice}>
                Ожидается повышение цены до {item.futurePrice} руб
              </span>
            )}
          </div>
        </div>
        <div className={styles.body}>
          <div
            className={styles.name}
            dangerouslySetInnerHTML={{ __html: item.name }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventItem;
