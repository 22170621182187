import classNames from "classnames";
import Contacts from "entities/contacts/Contacts";
import Navigation from "entities/navigation/Navigation";
import Social from "features/social/Social";
import { Themes } from "shared/lib/theme";
import styles from "widgets/layout/Footer.module.less";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={classNames(styles.grow, styles.start)}>
        <Social />
      </div>
      <div className={classNames(styles.grow, styles.nav)}>
        <Navigation theme={Themes.dark} />
      </div>
      <div className={classNames(styles.grow, styles.end)}>
        <Contacts />
      </div>
    </footer>
  );
}

export default Footer;
