import styles from "shared/components/HeaderLink.module.less";

import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Themes } from "shared/lib/theme";

interface Props {
  title: string;
  to?: string;
  href?: string;
  theme?: string;
}

function HeaderLink({ title, to, href, theme = Themes.light }: Props) {
  if (to) {
    return (
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(styles.headerLink, {
            [styles.active]: isActive,
            [styles.dark]: theme === Themes.dark,
          })
        }
      >
        {title}
      </NavLink>
    );
  }
  return (
    <a
      href={href}
      className={classNames(styles.headerLink, {
        [styles.dark]: theme === Themes.dark,
      })}
    >
      {title}
    </a>
  );
}

export default HeaderLink;
