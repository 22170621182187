import { Outlet } from "react-router-dom";

import Header from "widgets/layout/Header";
import Footer from "widgets/layout/Footer";

import styles from "widgets/layout/Layout.module.less";
import BasketIcon from "features/basket/BasketIcon";

function Layout({ background }: { background: string }) {
  const backgroundImage = `url(${background})`;
  return (
    <div className={styles.wrapper} style={{ backgroundImage }}>
      <Header />
      <section className={styles.body}>
        <Outlet />
        <BasketIcon />
      </section>
      <Footer />
    </div>
  );
}

export default Layout;
