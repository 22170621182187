// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oiJ5eHZMulSBcCP6pyK7 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 1rem;\n}\n.HcLwTalmsGFdum_554yB {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  align-items: center;\n}\n.IhipEDS8gdfOp_2VVe5M {\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/oops/Oops.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,aAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAFF;AAKA;EACE,mBAAA;AAHF","sourcesContent":["@import \"app/styles/const.less\";\n\n.center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 1rem;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  align-items: center;\n}\n\n.noWrap {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "oiJ5eHZMulSBcCP6pyK7",
	"row": "HcLwTalmsGFdum_554yB",
	"noWrap": "IhipEDS8gdfOp_2VVe5M"
};
export default ___CSS_LOADER_EXPORT___;
