import { useEffect } from "react";
import styles from "./ArchiveEventPage.module.less";

import archiveBackground from "app/assets/archiveBackground.webp";
import ArchiveEvent from "widgets/archive/components/ArchiveEvent";
import { useParams } from "react-router-dom";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const ArchiveEventPage = ({ setBackgroundImage }: Props) => {
  const { id } = useParams();

  useEffect(() => {
    setBackgroundImage(archiveBackground);
  }, [setBackgroundImage]);

  return (
    <div className={styles.container}>
      <ArchiveEvent id={id || "1"} />
    </div>
  );
};

export default ArchiveEventPage;
