import { ThemeContext, Themes } from "shared/lib/theme";

export type ThemedImageSrcType = {
  [Themes.dark]: string;
  [Themes.light]: string;
};

interface Props {
  className: string;
  srcs: ThemedImageSrcType;
  alt: string;
  onClick?: () => void | null;
}

function ThemedImage({ className, srcs, alt, onClick }: Props) {
  return (
    <ThemeContext.Consumer>
      {({ theme }: { theme: Themes }) => {
        return (
          <img
            className={className}
            src={srcs[theme]}
            onClick={onClick}
            alt={alt}
          />
        );
      }}
    </ThemeContext.Consumer>
  );
}

export default ThemedImage;
