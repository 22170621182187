import { useEffect } from "react";
import styles from "./AboutPage.module.less";

import aboutBackground from "app/assets/aboutBackground.webp";
import classNames from "classnames";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const AboutPage = ({ setBackgroundImage }: Props) => {
  useEffect(() => {
    setBackgroundImage(aboutBackground);
  }, [setBackgroundImage]);

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.title}>
          Концертное агентство «Драйв-групп»
          <br />
          основной организатор рок-концертов в Ижевске
        </p>
        <p className={styles.text}>
          В обойме «Драйв-групп» такие артисты как:
          <br />
          КняZz, Горшенёв, Крематорий, Эпидемия, Louna, Amatori, Stigmata, Слот,
          Animal Jazz, Тараканы, Анимация, Мураками, Наив, Йорш, F.P.G и многие
          другие
        </p>
        <div className={styles.spacerInner} />
        <p className={classNames(styles.title, styles.textBottom)}>
          «Драйв-групп» - организатор крупнейшего музыкального фестиваля YLETAЙ
        </p>
        <p className={classNames(styles.text, styles.textBottom)}>
          Мы - профессионалы своего дела, имеющие большой опыт работы. Мы дарим
          нашим зрителям то дорогое, что есть в жизни каждого из нас –
          впечатления, эмоции, воспоминания, которые невозможно забыть!
        </p>
      </div>
      <div className={styles.spacer} />
    </div>
  );
};

export default AboutPage;
