import Loader from "shared/components/Loader";
import { useGetShopsQuery } from "api/ticketsApi";
import NavTab from "entities/tabs/NavTab";
import NavTabs from "entities/tabs/NavTabs";

const Shops = () => {
  const { data: shops } = useGetShopsQuery(undefined);

  if (!shops) {
    return <Loader />;
  }

  return (
    <NavTabs>
      {shops &&
        shops.map(({ id, name, slug }) => (
          <NavTab key={id} title={name} to={`/tickets/${slug}`} />
        ))}
    </NavTabs>
  );
};

export default Shops;
