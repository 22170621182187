import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

export const processFetchOrder = createAsyncThunk(
  "order/fetch",
  async (orderId: number, thunkAPI) => {
    const { data } = await axios.get(
      `${backendUrl}/ticket/api/order?order_id=${orderId}`
    );
    return data;
  }
);
