// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oqkfvVWjqVUs5dvltzcO {\n  padding: 1rem;\n  align-self: center;\n  max-width: 100rem;\n}\n", "",{"version":3,"sources":["webpack://./src/features/tickets/components/Tickets.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EAEA,iBAAA;AAAF","sourcesContent":[".container {\n  padding: 1rem;\n  align-self: center;\n\n  max-width: 100rem;\n}\n\n// .container {\n//   display: flex;\n//   justify-content: center;\n//   align-items: center;\n//   height: 100%;\n// }\n\n// .grid {\n//   display: grid;\n//   width: fit-content;\n//   column-gap: 0.25rem;\n//   row-gap: 0.25rem;\n//   grid-template-columns: repeat(7, 2rem);\n\n//   padding: 2rem;\n//   background-color: rgba(var(--background-color--rgb), 0.5);\n//   border-radius: 2rem;\n// }\n\n// .column {\n//   display: flex;\n//   justify-content: center;\n//   align-items: center;\n//   height: 2rem;\n\n//   user-select: none;\n// }\n\n// .column.visible {\n//   border: 1px solid var(--foreground-color);\n//   cursor: pointer;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "oqkfvVWjqVUs5dvltzcO"
};
export default ___CSS_LOADER_EXPORT___;
