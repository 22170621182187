import { createSlice } from "@reduxjs/toolkit";
import { EventItemType } from "api/ticketsApi";
import { processAddToBasket } from "processes/basket/addToBasketProcess";
import { processDeleteFromBasket } from "processes/basket/deleteFromBasketProcess";
import { processRemoveFromBasket } from "processes/basket/removeFromBasketProcess";
import { processUpdateBasket } from "processes/basket/updateBasketProcess";

export interface BasketItem {
  amount: number;
  count: number;
  item: EventItemType;
}

interface StateType {
  pending: boolean;
  count: number;
  total: number;
  items: Array<BasketItem>;
}

const initialState: StateType = {
  pending: false,
  count: 0,
  total: 0,
  items: [],
};

export const slice = createSlice({
  name: "basket",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processAddToBasket.pending, (state) => {
        state.pending = true;
      })
      .addCase(processAddToBasket.fulfilled, (state, action) => {
        state.pending = false;
        state.items = action.payload.items;
        state.count = action.payload.count;
        state.total = action.payload.total;
      })
      .addCase(processAddToBasket.rejected, (state) => {
        state.pending = false;
      })
      .addCase(processDeleteFromBasket.pending, (state) => {
        state.pending = true;
      })
      .addCase(processDeleteFromBasket.fulfilled, (state, action) => {
        state.pending = false;
        state.items = action.payload.items;
        state.count = action.payload.count;
        state.total = action.payload.total;
      })
      .addCase(processDeleteFromBasket.rejected, (state) => {
        state.pending = false;
      })
      .addCase(processRemoveFromBasket.pending, (state) => {
        state.pending = true;
      })
      .addCase(processRemoveFromBasket.fulfilled, (state, action) => {
        state.pending = false;
        state.items = action.payload.items;
        state.count = action.payload.count;
        state.total = action.payload.total;
      })
      .addCase(processRemoveFromBasket.rejected, (state) => {
        state.pending = false;
      })
      .addCase(processUpdateBasket.fulfilled, (state, action) => {
        state.items = action.payload.items;
        state.count = action.payload.count;
        state.total = action.payload.total;
      });
  },
});

export default slice.reducer;
