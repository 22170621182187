import styles from "features/social/vk/SocialVk.module.less";

import vkDark from "app/assets/vk_dark.png";
import vkLight from "app/assets/vk_light.png";
import { Themes } from "shared/lib/theme";
import ThemedImage, { ThemedImageSrcType } from "shared/components/ThemedImage";

const imgs: ThemedImageSrcType = {
  [Themes.dark]: vkDark,
  [Themes.light]: vkLight,
};

function SocialVk() {
  return (
    <a
      className={styles.link}
      href="https://vk.com/draivizh"
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.center}>
        <ThemedImage className={styles.img} srcs={imgs} alt="Вконтакте" />
        {/* <img className={styles.img} src={vk} alt="Вконтакте" /> */}
      </div>
      <div className={styles.separator} />
      <div className={styles.center}>
        <div className={styles.text}>vk.com/draivizh</div>
      </div>
    </a>
  );
}

export default SocialVk;
