import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ticketsApi from "api/ticketsApi";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import account from "./accountSlice";
import basket from "./basketSlice";
import order from "./orderSlice";

const reducer = combineReducers({
  [ticketsApi.reducerPath]: ticketsApi.reducer,
  account,
  basket,
  order,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ticketsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

declare global {
  var appStore: typeof store;
}

globalThis.appStore = store;
