import styles from "./ListEvent.module.less";
import { EventType } from "api/ticketsApi";
import { Link } from "react-router-dom";
import EventItem from "./EventItem";

interface Props {
  event: EventType;
}

export const Event = ({ event }: Props) => {
  if (!event.items || event.items.length === 0) return null;
  return (
    <div className={styles.container}>
      <Link className={styles.link} to={`/tickets/${event.slug}/${event.id}`}>
        {event.name}
      </Link>
      {event.items?.map((item) => (
        <EventItem key={item.id} item={item} />
      ))}
    </div>
  );
};

export default Event;
