import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

const { homepage } = require("../../../package.json");

export const withRouter = (component: () => React.ReactNode) => () =>
  (
    <BrowserRouter basename={homepage}>
      <Suspense fallback="Loading...">{component()}</Suspense>
    </BrowserRouter>
  );
