import styles from "entities/contacts/Contacts.module.less";

function Contacts() {
  return (
      <div className={styles.contacts}>
          <p className={styles.line}>г. Ижевск, ул. Тимирязева 3,</p>
          <p className={styles.line}>офис компании Рондамит,</p>
          <p className={styles.line}>
              <a href="tel:+8 (3412) 900-920">+8 (3412) 900-920</a>
          </p>
          <p className={styles.line}>МЫ ВКОНТАКТЕ:</p>
          <p className={styles.line}>Концертное агентство "Драйв-групп" <a href="https://vk.com/draivizh" target="_blank">https://vk.com/draivizh</a></p>
          <p className={styles.line}>Фестиваль YLETAЙ <a href="https://vk.com/uletayfest"
                                                         target="_blank">https://vk.com/uletayfest</a></p>
      </div>
  );
}

export default Contacts;
