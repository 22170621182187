// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gDoqCY3drvdMtZajrkVM {\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  color: var(--background-color);\n  text-decoration: none;\n  border: 0;\n  border-bottom: 2px solid transparent;\n  text-align: center;\n}\n.gDoqCY3drvdMtZajrkVM:hover,\n.gDoqCY3drvdMtZajrkVM:active,\n.gDoqCY3drvdMtZajrkVM:focus {\n  text-decoration: none;\n  border-bottom: 2px solid var(--background-color);\n}\n.oqqV6ighkljUfIiZjdoe {\n  text-decoration: none;\n  border-bottom: 2px solid var(--background-color);\n}\n", "",{"version":3,"sources":["webpack://./src/entities/tabs/Tab.module.less"],"names":[],"mappings":"AAEA;EACE,qDAAA;EACA,8BAAA;EACA,qBAAA;EACA,SAAA;EACA,oCAAA;EACA,kBAAA;AADF;AAGE;;;EAGE,qBAAA;EACA,gDAAA;AADJ;AAKA;EACE,qBAAA;EACA,gDAAA;AAHF","sourcesContent":["@import \"app/styles/const.less\";\n\n.tab {\n  font-family: @primaryFontName;\n  color: var(--background-color);\n  text-decoration: none;\n  border: 0;\n  border-bottom: 2px solid transparent;\n  text-align: center;\n\n  &:hover,\n  &:active,\n  &:focus {\n    text-decoration: none;\n    border-bottom: 2px solid var(--background-color);\n  }\n}\n\n.active {\n  text-decoration: none;\n  border-bottom: 2px solid var(--background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": "gDoqCY3drvdMtZajrkVM",
	"active": "oqqV6ighkljUfIiZjdoe"
};
export default ___CSS_LOADER_EXPORT___;
