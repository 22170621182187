import styles from "./Carousel.module.less";

import classNames from "classnames";
import { MouseEventHandler, useState } from "react";
import Image from "shared/components/Image";

interface Props {
  photos: string[];
}

const getNext = (current: number, total: number) => {
  if (current === total - 1) return 0;
  return current + 1;
};

const getPrev = (current: number, total: number) => {
  if (current > 0) {
    return current - 1;
  }
  return total - 1;
};

function Carousel({ photos }: Props) {
  const [next, setNext] = useState(1);
  const [prev, setPrev] = useState(photos.length - 1);
  const [active, setActive] = useState(0);

  const updateActive = (newActive: number) => {
    console.log();
    setNext(getNext(newActive, photos.length));
    setPrev(getPrev(newActive, photos.length));
    setActive(newActive);
  };

  const activateNext: MouseEventHandler<HTMLImageElement> = () => {
    if (active > photos.length - 2) {
      updateActive(0);
    } else {
      updateActive(active + 1);
    }
  };

  const activatePrev: MouseEventHandler<HTMLImageElement> = () => {
    if (active > 0) {
      updateActive(active - 1);
    } else {
      updateActive(photos.length - 1);
    }
  };

  const getHandler = (idx: number) => {
    if (next === idx) return activateNext;
    if (prev === idx) return activatePrev;
    return undefined;
  };

  return (
    <div className={styles.container}>
      <div className={styles.photos}>
        {photos.map((photo, idx) => (
          <div
            className={classNames(styles.photoContainer, {
              [styles.active]: active === idx,
              [styles.next]: next === idx,
              [styles.prev]: prev === idx,
            })}
          >
            <Image
              key={photo}
              className={styles.photo}
              src={photo}
              alt={photo}
              onClick={getHandler(idx)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
