import { useCallback, useEffect } from "react";
import styles from "./BasketPage.module.less";

import akkBackground from "app/assets/akkBackground.webp";
import BasketItems from "features/basket/BasketItems";
import {
  selectBasketCount,
  selectBasketTotal,
} from "features/tickets/selectors";
import { useAppDispatch, useAppSelector } from "store";
import { Link } from "react-router-dom";
import { processCheckout } from "processes/basket/checkoutProcess";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const BasketPage = ({ setBackgroundImage }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setBackgroundImage(akkBackground);
  }, [setBackgroundImage]);

  const total = useAppSelector(selectBasketTotal);
  const count = useAppSelector(selectBasketCount);

  const handleBuy = useCallback(() => dispatch(processCheckout()), [dispatch]);

  if (!count) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.totalEmpty}>
            <div className={styles.sum}>Корзина пуста</div>
            <Link to="/tickets" className={styles.btn}>
              К покупкам
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>Корзина</div>
        <BasketItems />
        <div className={styles.total}>
          <div className={styles.comment}>Итого:</div>
          <div className={styles.sum}>{total} руб</div>
        </div>
        <div className={styles.buy}>
          <button className={styles.btn} onClick={handleBuy}>
            Оформить заказ
          </button>
        </div>
      </div>
    </div>
  );
};

export default BasketPage;
