// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nvrwQJRf3dFrK648ytSA {\n  padding: 1rem;\n  align-self: center;\n  max-width: 100rem;\n}\n.f2FkS53zpxE2rqCrseJN {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/event/EventPage.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EAEA,iBAAA;AAAF;AAGA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;AADF","sourcesContent":[".event {\n  padding: 1rem;\n  align-self: center;\n\n  max-width: 100rem;\n}\n\n.container {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"event": "nvrwQJRf3dFrK648ytSA",
	"container": "f2FkS53zpxE2rqCrseJN"
};
export default ___CSS_LOADER_EXPORT___;
