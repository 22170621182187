import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

export const processRemoveFromBasket = createAsyncThunk(
  "basket/removeFromBasket",
  async (itemId, thunkAPI) => {
    const { data } = await axios.post(`${backendUrl}/ticket/api/remove`, {
      itemId,
    });
    return data;
  }
);
