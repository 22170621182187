import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

export const processAddToBasket = createAsyncThunk(
  "basket/addToBasket",
  async (itemId: number, thunkAPI) => {
    const { data } = await axios.post(`${backendUrl}/ticket/api/add`, {
      itemId,
    });
    return data;
  }
);
