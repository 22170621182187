import styles from "./BasketItems.module.less";
import { useAppSelector } from "store";
import { selectBasketItems } from "features/tickets/selectors";
import BasketItem from "./BasketItem";

function BasketItems() {
  const items = useAppSelector(selectBasketItems);

  if (!items) return null;
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <BasketItem key={item.item.id} item={item} />
      ))}
    </div>
  );
}

export default BasketItems;
