import styles from "./Event.module.less";
import { EventType } from "api/ticketsApi";
import EventItem from "./EventItem";

interface Props {
  event: EventType;
}

export const Event = ({ event }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.name}>{event.name}</div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: event.description }}
      />
      {event.items?.map((item) => (
        <EventItem key={item.id} item={item} />
      ))}
    </div>
  );
};

export default Event;
