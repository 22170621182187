// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JONlePZdpSLfMFrfxx7n {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n.vxKPJrBygH9ahQQ6VAto {\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  color: var(--foreground-color);\n  font-size: 1.5em;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/entities/contacts/Contacts.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;AADF;AAIA;EACE,qDAAA;EACA,8BAAA;EACA,gBAAA;EACA,SAAA;AAFF","sourcesContent":["@import \"app/styles/const.less\";\n\n.contacts {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.line {\n  font-family: @primaryFontName;\n  color: var(--foreground-color);\n  font-size: 1.5em;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts": "JONlePZdpSLfMFrfxx7n",
	"line": "vxKPJrBygH9ahQQ6VAto"
};
export default ___CSS_LOADER_EXPORT___;
