import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

export const processLogout = createAsyncThunk(
  "account/logout",
  async (_, thunkAPI) => {
    await axios.post(`${backendUrl}/ticket/api/logout`);
    return null;
  }
);
