import styles from "./BasketIcon.module.less";

import { Link, useLocation } from "react-router-dom";

import basketDark from "app/assets/basket_dark.png";
import basketLight from "app/assets/basket_light.png";
import ThemedImage, { ThemedImageSrcType } from "shared/components/ThemedImage";
import { Themes } from "shared/lib/theme";
import { useAppSelector } from "store";
import { selectBasketCount } from "features/tickets/selectors";
import { useEffect, useRef, useState } from "react";
import throttle from "shared/lib/throttle";

const imgs: ThemedImageSrcType = {
  [Themes.dark]: basketLight,
  [Themes.light]: basketDark,
};

function BasketIcon() {
  const count = useAppSelector(selectBasketCount);
  const ref = useRef<HTMLAnchorElement>(null);
  const [absolute, setAbsolute] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    const onScroll = throttle((e) => {
      if (!ref.current) return;
      if (!ref.current.parentElement) return;
      const windowHeight = window.innerHeight;
      const parentRects = ref.current.parentElement.getClientRects()[0];

      setAbsolute(parentRects.bottom <= windowHeight);
    }, 10);

    onScroll();
    // при прокрутке страницы
    window.addEventListener("scroll", onScroll, { passive: true });
    // при изменении размера страницы
    // window.addEventListener("resize", onScroll, { passive: true });
    // при подгрузке контента
    const intervalId = setInterval(onScroll, 500);
    return () => {
      window.removeEventListener("scroll", onScroll);
      // window.removeEventListener("resize", onScroll);
      clearInterval(intervalId);
    };
  }, []);

  if (!count || pathname.startsWith("/basket")) return null;
  return (
    <Link
      className={styles.container}
      to="/basket"
      ref={ref}
      style={{
        position: absolute ? "absolute" : "fixed",
      }}
    >
      <div className={styles.count}>{count}</div>
      <ThemedImage srcs={imgs} className={styles.basket} alt="Корзина" />
      <div className={styles.text}>в корзине</div>
    </Link>
  );
}

export default BasketIcon;
