// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UNFCNJm67ZUD6LzsrkGG {\n  display: flex;\n  gap: 1rem;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n@media (max-width: 768px) {\n  .UNFCNJm67ZUD6LzsrkGG {\n    justify-content: center;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/entities/navigation/Navigation.module.less","webpack://./src/app/styles/const.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,eAAA;EACA,8BAAA;AADF;ACAE;EAAA;IDIE,uBAAA;EAAF;AACF","sourcesContent":["@import \"app/styles/const.less\";\n\n.navigation {\n  display: flex;\n  gap: 1rem;\n  flex-wrap: wrap;\n  justify-content: space-between;\n\n  .mobile({\n    justify-content: center;\n  });\n}\n","@fontDefaultSize: 14px;\n@fontHalfSize: @fontDefaultSize / 2;\n@primaryFontName: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n\n.mobile(@rules) {\n  @media (max-width: 768px) {\n    @rules();\n  }\n}\n\n.tablet(@rules) {\n  @media (min-width: 768px) and (max-width: 1200px) {\n    @rules();\n  }\n}\n\n.desktop(@rules) {\n  @media (min-width: 1200px) {\n    @rules();\n  }\n}\n\n// Area site\n// .area {\n//   margin-left: auto;\n//   margin-right: auto;\n\n//   .tablet ({\n//     // width: @tablet-area;\n//   });;\n\n//   .desktop ({\n//     // width: @desktop-area;\n//   });;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "UNFCNJm67ZUD6LzsrkGG"
};
export default ___CSS_LOADER_EXPORT___;
