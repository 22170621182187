import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

export const processUpdateBasket = createAsyncThunk(
  "basket/updateBasket",
  async (_, thunkAPI) => {
    const { data } = await axios.get(`${backendUrl}/ticket/api/getbasket`);
    return data;
  }
);
