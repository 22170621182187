import styles from "./Tickets.module.less";
import { useParams } from "react-router-dom";
import { useGetEventsBySlugQuery } from "api/ticketsApi";
import ListEvent from "./ListEvent";
import Loader from "shared/components/Loader";

export const Tickets = () => {
  const { slug } = useParams();
  const { data: events, isLoading } = useGetEventsBySlugQuery(String(slug));

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {events?.map((event) => (
        <ListEvent key={event.id} event={event} />
      ))}
    </div>
  );
};
