import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";
import { processUpdateBasket } from "./updateBasketProcess";

export const processCheckout = createAsyncThunk(
  "basket/checkout",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${backendUrl}/ticket/api/ordercheckout`
      );
      return data;
    } catch (e: any) {
      const message = e?.response?.data?.error;
      console.error("processCheckout", message || e);
      if (message) alert(message);
    } finally {
      thunkAPI.dispatch(processUpdateBasket());
    }
  }
);
