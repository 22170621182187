// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R5kdDHF_DxIw6RoAE2ux {\n  display: block;\n  font-weight: bold;\n  font-size: 2.5rem;\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  margin-bottom: 1rem;\n}\n@media (max-width: 768px) {\n  .R5kdDHF_DxIw6RoAE2ux {\n    font-size: 2rem;\n    margin-bottom: 1rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/features/tickets/components/ListEvent.module.less","webpack://./src/app/styles/const.less"],"names":[],"mappings":"AAMA;EACE,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,qDAAA;EACA,mBAAA;AALF;ACDE;EAAA;IDSE,eAAA;IACA,mBAAA;EAJF;AACF","sourcesContent":["@import \"app/styles/const.less\";\n\n.container {\n  // margin-bottom: 3rem;\n}\n\n.link {\n  display: block;\n  font-weight: bold;\n  font-size: 2.5rem;\n  font-family: @primaryFontName;\n  margin-bottom: 1rem;\n\n  .mobile({\n    font-size: 2rem;\n    margin-bottom: 1rem;\n  });\n}\n","@fontDefaultSize: 14px;\n@fontHalfSize: @fontDefaultSize / 2;\n@primaryFontName: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n\n.mobile(@rules) {\n  @media (max-width: 768px) {\n    @rules();\n  }\n}\n\n.tablet(@rules) {\n  @media (min-width: 768px) and (max-width: 1200px) {\n    @rules();\n  }\n}\n\n.desktop(@rules) {\n  @media (min-width: 1200px) {\n    @rules();\n  }\n}\n\n// Area site\n// .area {\n//   margin-left: auto;\n//   margin-right: auto;\n\n//   .tablet ({\n//     // width: @tablet-area;\n//   });;\n\n//   .desktop ({\n//     // width: @desktop-area;\n//   });;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "R5kdDHF_DxIw6RoAE2ux"
};
export default ___CSS_LOADER_EXPORT___;
