import { useEffect } from "react";
import styles from "./AkkPage.module.less";

import akkBackground from "app/assets/akkBackground.webp";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const AkkPage = ({ setBackgroundImage }: Props) => {
  useEffect(() => {
    setBackgroundImage(akkBackground);
  }, [setBackgroundImage]);

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <p className={styles.title}>Аккредитация:</p>
        <p>
          <a href="https://vk.com/draivizh" target="_blank" rel="noreferrer">
            vk.com/draivizh
          </a>
        </p>
        <p>
          <a href="tel:+7 (912) 464-00-90">+7 (912) 464-00-90</a>
        </p>
        <p>
          <a href="tel:+8 (3412) 900-920">+8 (3412) 900-920</a>
        </p>
      </div>
    </div>
  );
};

export default AkkPage;
