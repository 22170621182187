// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JgoDRiILoQDiSTjQTeHS {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  justify-content: center;\n  align-items: center;\n}\n.JGUYlMPQOJ25bh58C3ga {\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  font-size: 1.5rem;\n  margin: 0;\n  text-align: center;\n  text-shadow: 0 0 0.5rem var(--background-color);\n  background-color: rgba(var(--background-color--rgb), 0.35);\n  border-radius: 2rem;\n  padding: 2rem;\n}\n.ippl95sCkBFanLW5SV6i {\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  font-size: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/contacts/ContactsPage.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAIA;EACE,qDAAA;EACA,iBAAA;EACA,SAAA;EACA,kBAAA;EACA,+CAAA;EAEA,0DAAA;EACA,mBAAA;EACA,aAAA;AAHF;AAMA;EACE,qDAAA;EACA,eAAA;AAJF","sourcesContent":["@import \"app/styles/const.less\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  justify-content: center;\n  align-items: center;\n}\n\n.text {\n  font-family: @primaryFontName;\n  font-size: 1.5rem;\n  margin: 0;\n  text-align: center;\n  text-shadow: 0 0 0.5rem var(--background-color);\n\n  background-color: rgba(var(--background-color--rgb), 0.35);\n  border-radius: 2rem;\n  padding: 2rem;\n}\n\n.title {\n  font-family: @primaryFontName;\n  font-size: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "JgoDRiILoQDiSTjQTeHS",
	"text": "JGUYlMPQOJ25bh58C3ga",
	"title": "ippl95sCkBFanLW5SV6i"
};
export default ___CSS_LOADER_EXPORT___;
