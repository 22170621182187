import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "widgets/layout/Layout";

import IndexPage from "./index/IndexPage";
import OopsPage from "./oops/OopsPage";

import indexBackground from "../app/assets/indexBackground.webp";
import AboutPage from "./about/AboutPage";
import AkkPage from "./akk/AkkPage";
import ContactsPage from "./contacts/ContactsPage";
import ArchivePage from "./archive/components/ArchivePage";
import ArchiveEventPage from "./archive/components/ArchiveEventPage";
import TicketsPage from "./tickets/TicketsPage";
import EventPage from "./event/EventPage";
import { useAppDispatch } from "store";
import { processUpdateBasket } from "processes/basket/updateBasketProcess";
import BasketPage from "./basket/BasketPage";
import useScrollToTop from "shared/hooks/useScrollToTop";

export const Routing = () => {
  const dispatch = useAppDispatch();
  const [background, setBackground] = useState(indexBackground);
  useScrollToTop();

  useEffect(() => {
    dispatch(processUpdateBasket());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Layout background={background} />}>
        <Route
          index
          element={<IndexPage setBackgroundImage={setBackground} />}
        />
        <Route
          path="about"
          element={<AboutPage setBackgroundImage={setBackground} />}
        />
        <Route
          path="akk"
          element={<AkkPage setBackgroundImage={setBackground} />}
        />
        <Route path="archive">
          <Route
            index
            element={<ArchivePage setBackgroundImage={setBackground} />}
          />
          <Route
            path="event/:id"
            element={<ArchiveEventPage setBackgroundImage={setBackground} />}
          />
        </Route>
        <Route
          path="contacts"
          element={<ContactsPage setBackgroundImage={setBackground} />}
        />
        <Route
          path="tickets"
          element={<TicketsPage setBackgroundImage={setBackground} />}
        />
        <Route
          path="tickets/:slug"
          element={<TicketsPage setBackgroundImage={setBackground} />}
        />
        <Route
          path="tickets/:slug/:eventId"
          element={<EventPage setBackgroundImage={setBackground} />}
        />
        <Route
          path="basket"
          element={<BasketPage setBackgroundImage={setBackground} />}
        />
      </Route>
      <Route path="*" element={<OopsPage />} />
    </Routes>
  );
};
