import { useEffect } from "react";
import styles from "./ArchivePage.module.less";

import archiveBackground from "app/assets/archiveBackground.webp";
import EventCard from "widgets/archive/components/EventCard";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const ArchivePage = ({ setBackgroundImage }: Props) => {
  useEffect(() => {
    setBackgroundImage(archiveBackground);
  }, [setBackgroundImage]);

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.item}>
          <EventCard id="1" />
        </div>
        <div className={styles.item}>
          <EventCard id="2" />
        </div>
        <div className={styles.item}>
          <EventCard id="3" />
        </div>
        <div className={styles.item}>
          <EventCard id="4" />
        </div>
        <div className={styles.item}>
          <EventCard id="5" />
        </div>
        <div className={styles.item}>
          <EventCard id="6" />
        </div>
      </div>
    </div>
  );
};

export default ArchivePage;
