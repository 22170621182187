// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LzuDBmq0PVeFDC8IuvP9 {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  justify-content: center;\n  align-items: start;\n  width: 100%;\n}\n.p4f8V4CppLcQJ7XBcgqF {\n  font-family: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n  font-size: 2rem;\n  color: var(--foreground-color);\n  margin: 1rem 2rem 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/archive/components/ArchiveEventPage.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AADF;AAIA;EACE,qDAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;AAFF","sourcesContent":["@import \"app/styles/const.less\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  justify-content: center;\n  align-items: start;\n  width: 100%;\n}\n\n.link {\n  font-family: @primaryFontName;\n  font-size: 2rem;\n  color: var(--foreground-color);\n  margin: 1rem 2rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LzuDBmq0PVeFDC8IuvP9",
	"link": "p4f8V4CppLcQJ7XBcgqF"
};
export default ___CSS_LOADER_EXPORT___;
