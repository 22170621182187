import Carousel from "shared/components/Carousel";

interface Props {
  id: string;
}

type Config = {
  [id: string]: number;
};

const config: Config = {
  "1": 9,
  "2": 10,
  "3": 9,
  "4": 8,
  "5": 9,
  "6": 6,
};

function ArchiveEvent({ id }: Props) {
  const photos = Array.from(Array<number>(config[id]).keys()).map(
    (number) => `${process.env.PUBLIC_URL}/img/archive/${id}-${number + 1}.jpg`
  );
  return <Carousel photos={photos} />;
}

export default ArchiveEvent;
