import styles from "./TicketsPage.module.less";
import { Tickets } from "features/tickets/components/Tickets";
import { useEffect } from "react";

import akkBackground from "app/assets/akkBackground.webp";
import { useGetShopsQuery } from "api/ticketsApi";
import Loader from "shared/components/Loader";
import { Navigate, useParams } from "react-router";
import Shops from "features/shops/Shops";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const TicketsPage = ({ setBackgroundImage }: Props) => {
  useEffect(() => {
    setBackgroundImage(akkBackground);
  }, [setBackgroundImage]);

  const { slug } = useParams();
  const { data: shops, isLoading } = useGetShopsQuery(undefined);

  if (!slug && shops && shops.length) {
    return <Navigate to={`/tickets/${shops[0].slug}`} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Shops />
      <Tickets />
    </div>
  );
};

export default TicketsPage;
