import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

export const processDeleteFromBasket = createAsyncThunk(
  "basket/deleteFromBasket",
  async (itemId: number, thunkAPI) => {
    const { data } = await axios.post(`${backendUrl}/ticket/api/delete`, {
      itemId,
    });
    return data;
  }
);
