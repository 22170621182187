import styles from "./Tabs.module.less";

interface Props {
  children: React.ReactNode;
}

function NavTabs({ children }: Props) {
  return <div className={styles.tabs}>{children}</div>;
}

export default NavTabs;
