import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

export const processCheckLogin = createAsyncThunk(
  "account/checkLogin",
  async (_, thunkAPI) => {
    const { data } = await axios.post(`${backendUrl}/ticket/api/checklogin`);
    return data;
  }
);
