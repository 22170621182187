import styles from "./Loader.module.less";

function Loader() {
  return (
    <div className={styles.ldsCircle}>
      <div></div>
    </div>
    // <div className={styles.ldsRing}>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    // </div>
    // <div className={styles.ldsSpinner}>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    // </div>
    // <div className={styles.ldsEllipsis}>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    // </div>
  );
}

export default Loader;
