// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ETesVbRVCsl6Z6EsfMK7 {\n  object-fit: cover;\n  height: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/shared/components/Image.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF","sourcesContent":[".img {\n  object-fit: cover;\n  height: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "ETesVbRVCsl6Z6EsfMK7"
};
export default ___CSS_LOADER_EXPORT___;
