import styles from "./Navigation.module.less";

import HeaderLink from "shared/components/HeaderLink";
import { Themes } from "shared/lib/theme";

interface Props {
  theme?: Themes;
}

function Navigation({ theme = Themes.light }: Props) {
  return (
    <div className={styles.navigation}>
      <HeaderLink title="Главная" to="/" theme={theme} />
      <HeaderLink title="Концерты" to="/tickets" theme={theme} />
      <HeaderLink title="О нас" to="/about" theme={theme} />
      <HeaderLink title="Купить билет" href="/ticket/concert" theme={theme} />
      <HeaderLink title="Аккредитация" to="/akk" theme={theme} />
      <HeaderLink title="Архив" to="/archive" theme={theme} />
      <HeaderLink title="Контакты" to="/contacts" theme={theme} />
    </div>
  );
}

export default Navigation;
