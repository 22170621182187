import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendUrl } from "app/const";
import axios from "axios";

interface Params {
  username: string;
  password: string;
}

export const processLogin = createAsyncThunk(
  "account/login",
  async ({ username, password }: Params, { rejectWithValue }) => {
    const { data } = await axios.post(`${backendUrl}/ticket/api/login`, {
      username,
      password,
    });
    return data;
  }
);
