import { createAsyncThunk } from "@reduxjs/toolkit";

interface Params {
  username: string;
  password: string;
  passwordRepeat: string;
}

export const processRegister = createAsyncThunk(
  "account/register",
  async ({ username, password, passwordRepeat }: Params, thunkAPI) => {
    // const data = await fetchCount(username, password);
    await new Promise((resolve) => setTimeout(resolve, 5000));
    if (password !== passwordRepeat) {
      throw new Error("Passwords do not match");
    }
    return { username };
  }
);
