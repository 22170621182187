import styles from "./BasketItem.module.less";
import classNames from "classnames";
import { useCallback } from "react";
import { backendUrl } from "app/const";
import { BasketItem as BasketItemType } from "store/basketSlice";
import { useAppDispatch, useAppSelector } from "store";
import { selectBasketPending } from "features/tickets/selectors";
import { processAddToBasket } from "processes/basket/addToBasketProcess";
import { processDeleteFromBasket } from "processes/basket/deleteFromBasketProcess";
import Loader from "shared/components/Loader";

interface Props {
  item: BasketItemType;
}

export const BasketItem = ({ item: basketItem }: Props) => {
  const { item } = basketItem;
  const dispatch = useAppDispatch();
  const basketPending = useAppSelector(selectBasketPending);

  const handleAddToBasket = useCallback(
    () => dispatch(processAddToBasket(item.id)),
    [dispatch, item]
  );

  const handleDelFromBasket = useCallback(
    () => dispatch(processDeleteFromBasket(item.id)),
    [dispatch, item]
  );

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url("${backendUrl + item.img}")` }}
    >
      <div className={classNames(styles.item, styles.withImg)}>
        <div
          className={styles.mobileName}
          dangerouslySetInnerHTML={{ __html: item.name }}
        />
        <div className={styles.logo}>
          <img className={styles.img} src={backendUrl + item.imgThumb} alt="" />
        </div>
        <div className={styles.title}>
          <div
            className={styles.name}
            dangerouslySetInnerHTML={{ __html: item.name }}
          />
          <div className={styles.price}>
            {basketItem.count} x {item.price} руб
          </div>
        </div>

        <div className={styles.priceContainer}>
          <div className={styles.actions}>
            <button
              className={classNames(styles.btn, styles.inBasketBtn)}
              onClick={handleDelFromBasket}
              disabled={basketPending}
            >
              {!basketPending ? "–" : <Loader />}
            </button>
            <div className={styles.count}>{basketItem.count}</div>
            <button
              className={classNames(styles.btn, styles.inBasketBtn)}
              onClick={handleAddToBasket}
              disabled={basketPending}
            >
              {!basketPending ? "+" : <Loader />}
            </button>
          </div>
        </div>
        <div className={styles.total}>{basketItem.count * item.price} руб</div>
      </div>
    </div>
  );
};

export default BasketItem;
