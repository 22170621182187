import { useEffect } from "react";
import styles from "./IndexPage.module.less";

import indexBackground from "app/assets/indexBackground.webp";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const IndexPage = ({ setBackgroundImage }: Props) => {
  useEffect(() => {
    setBackgroundImage(indexBackground);
  }, [setBackgroundImage]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            Профессиональная организация мероприятий с участием российских и
            зарубежных артистов
          </div>
        </div>
        <div className={styles.spacer} />
      </div>
    </>
  );
};

export default IndexPage;
