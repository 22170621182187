import Logo from "features/logo/Logo";
import styles from "widgets/layout/Header.module.less";
import Navigation from "entities/navigation/Navigation";

function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <Navigation />
    </header>
  );
}

export default Header;
