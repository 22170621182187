import { useEffect } from "react";
import styles from "./EventPage.module.less";

import akkBackground from "app/assets/akkBackground.webp";
import { useParams } from "react-router";
import Loader from "shared/components/Loader";
import Event from "features/tickets/components/Event";
import { useGetEventQuery } from "api/ticketsApi";
import Shops from "features/shops/Shops";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const EventPage = ({ setBackgroundImage }: Props) => {
  const { eventId } = useParams();
  const { data: event, isLoading: isEventLoading } = useGetEventQuery(
    Number(eventId)
  );

  useEffect(() => {
    setBackgroundImage(akkBackground);
  }, [setBackgroundImage]);

  if (!event || isEventLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Shops />
      <div className={styles.event}>
        <Event key={event.id} event={event} />
      </div>
    </div>
  );
};

export default EventPage;
