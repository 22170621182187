// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HMS1mVLSSvC42Fi3riP3 {\n  max-height: 100vh;\n  max-width: 100%;\n  border-radius: 2rem;\n  box-shadow: 0 0 2rem var(--background-color);\n}\n@media (max-width: 768px) {\n  .HMS1mVLSSvC42Fi3riP3 {\n    border-radius: 1rem;\n    box-shadow: 0 0 1rem var(--background-color);\n  }\n}\n.D0v0eE7biqNcuLc417DX {\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/widgets/archive/components/EventCard.module.less","webpack://./src/app/styles/const.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,4CAAA;AADF;ACAE;EAAA;IDIE,mBAAA;IACA,4CAAA;EAAF;AACF;AAGA;EACE,YAAA;AADF","sourcesContent":["@import \"app/styles/const.less\";\n\n.photo {\n  max-height: 100vh;\n  max-width: 100%;\n  border-radius: 2rem;\n  box-shadow: 0 0 2rem var(--background-color);\n\n  .mobile({\n    border-radius: 1rem;\n    box-shadow: 0 0 1rem var(--background-color);\n  });\n}\n\n.link {\n  height: 100%;\n}\n","@fontDefaultSize: 14px;\n@fontHalfSize: @fontDefaultSize / 2;\n@primaryFontName: \"Century Gothic\", \"__Akrobat_5\", \"Arial\";\n\n.mobile(@rules) {\n  @media (max-width: 768px) {\n    @rules();\n  }\n}\n\n.tablet(@rules) {\n  @media (min-width: 768px) and (max-width: 1200px) {\n    @rules();\n  }\n}\n\n.desktop(@rules) {\n  @media (min-width: 1200px) {\n    @rules();\n  }\n}\n\n// Area site\n// .area {\n//   margin-left: auto;\n//   margin-right: auto;\n\n//   .tablet ({\n//     // width: @tablet-area;\n//   });;\n\n//   .desktop ({\n//     // width: @desktop-area;\n//   });;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photo": "HMS1mVLSSvC42Fi3riP3",
	"link": "D0v0eE7biqNcuLc417DX"
};
export default ___CSS_LOADER_EXPORT___;
