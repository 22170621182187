import classNames from "classnames";
import { Link } from "react-router-dom";

// import Button from "../ui-kit/Button";
// import ButtonType from "../ui-kit/ButtonType";

// import baseStyles from "../ui-kit/Base.module.less";
import styles from "./Oops.module.less";

function OopsPage() {
  return (
    <div className={classNames(styles.center)}>
      <h2>Oops, что-то пошло не так</h2>
      <div className={styles.row}>
        <Link to="/" className={styles.noWrap}>
          На главную
        </Link>
        {/* <Button
          type={ButtonType.default}
          onClick={() => window.location.reload()}
        >
          Перезагрузить
        </Button> */}
      </div>
    </div>
  );
}

export default OopsPage;
