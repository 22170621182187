import classNames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./Tab.module.less";

interface Props {
  title: string;
  to: string;
}

function NavTab({ title, to }: Props) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.tab, {
          [styles.active]: isActive,
        })
      }
    >
      {title}
    </NavLink>
  );
}

export default NavTab;
