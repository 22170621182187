import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { BasketItem } from "store/basketSlice";

const selectBasketItem = createSelector(
  [
    // Usual first input - extract value from `state`
    (state) => state.basket.items,
    // Take the second arg, `category`, and forward to the output selector
    (state, itemId) => itemId,
  ],
  // Output selector gets (`items, category)` as args
  (items, itemId) =>
    items.find((basketItem: BasketItem) => basketItem.item.id === itemId)
);

const selectBasketPending = (state: RootState) => state.basket.pending;
const selectBasketCount = (state: RootState) => state.basket.count;
const selectBasketItems = (state: RootState) => state.basket.items;
const selectBasketTotal = (state: RootState) => state.basket.total;

const selectOrder = createSelector(
  [(state) => state.order.orders, (state, orderId) => orderId],
  (orders, orderId) => orders[orderId]
);

export {
  selectBasketItem,
  selectBasketPending,
  selectBasketCount,
  selectBasketItems,
  selectBasketTotal,
  selectOrder,
};
