import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backendUrl } from "app/const";

export interface EventItemType {
  id: number;
  name: string;
  description: string;
  shopId: number;
  eventId: number;
  categoryId: number;
  price: number;
  futurePrice: number;
  img: string;
  imgThumb: string;
  imgCover: string;
}

export interface EventType {
  id: number;
  name: string;
  description: string;
  shopId: number;
  slug: string;
  items: Array<EventItemType>;
}

export interface ShopType {
  id: number;
  name: string;
  slug: string;
}

const ticketsApi = createApi({
  reducerPath: "ticketsApi",
  baseQuery: fetchBaseQuery({ baseUrl: backendUrl + "/ticket/api/" }),
  endpoints: (builder) => ({
    getShops: builder.query<Array<ShopType>, undefined>({
      query: () => "shops",
    }),
    getEventsBySlug: builder.query<Array<EventType>, string>({
      query: (slug) => `events?slug=${slug}`,
    }),
    getEvent: builder.query<EventType, number>({
      query: (eventId) => `event?id=${eventId}`,
    }),
  }),
});

export const { useGetShopsQuery, useGetEventsBySlugQuery, useGetEventQuery } =
  ticketsApi;

export default ticketsApi;
