import { Routing } from "pages";
import { Provider } from "react-redux";
import { withProviders } from "./providers";

import ThemeContextWrapper from "shared/lib/theme";

import "./styles/index.less";
import { store } from "store";

function App() {
  return (
    <ThemeContextWrapper>
      <Provider store={store}>
        <Routing />
      </Provider>
    </ThemeContextWrapper>
  );
}

export default withProviders(App);
