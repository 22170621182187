import { createSlice } from "@reduxjs/toolkit";
import { processCheckLogin } from "processes/account/checkLoginProcess";
import { processLogin } from "../processes/account/loginProcess";
import { processLogout } from "../processes/account/logoutProcess";
import { processRegister } from "../processes/account/registerProcess";

interface AccountType {
  username: string;
}

interface StateType {
  pending: boolean;
  errors: string | undefined;
  account: AccountType | null;
}

const initialState: StateType = {
  pending: false,
  errors: undefined,
  account: null,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processLogin.pending, (state) => {
        state.pending = true;
        state.errors = undefined;
        state.account = null;
      })
      .addCase(processLogin.fulfilled, (state, action) => {
        state.pending = false;
        state.errors = undefined;
        state.account = action.payload;
      })
      .addCase(processLogin.rejected, (state, action) => {
        console.error(action);
        state.pending = false;
        state.errors = action.error.message;
      })
      .addCase(processLogout.pending, (state) => {
        state.pending = true;
        state.errors = undefined;
      })
      .addCase(processLogout.fulfilled, (state, action) => {
        state.pending = false;
        state.errors = undefined;
        state.account = null;
      })
      .addCase(processLogout.rejected, (state, action) => {
        state.pending = false;
        state.errors = action.error.message;
      })
      .addCase(processRegister.pending, (state) => {
        state.pending = true;
        state.errors = undefined;
        state.account = null;
      })
      .addCase(processRegister.fulfilled, (state, action) => {
        state.pending = false;
        state.errors = undefined;
        state.account = action.payload;
      })
      .addCase(processRegister.rejected, (state, action) => {
        state.pending = false;
        state.errors = action.error.message;
      })
      .addCase(processCheckLogin.fulfilled, (state, action) => {
        state.pending = false;
        state.errors = undefined;
        state.account = action.payload;
      })
      .addCase(processCheckLogin.rejected, (state, action) => {
        state.pending = false;
        state.errors = action.error.message;
        state.account = null;
      });
  },
});

export default accountSlice.reducer;
