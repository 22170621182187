// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lnaviO6L3CAXT5EFkhIQ {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow: hidden;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  background-attachment: fixed;\n  transition: background-image 500ms ease-in-out;\n}\n.Uda5kQyHOaU4GDmjWZgu {\n  display: flex;\n  position: relative;\n  flex-grow: 1;\n  flex-shrink: 1;\n  z-index: 0;\n  transition: backdrop-filter 0.5s ease;\n}\nhtml[data-theme=\"light\"] .Uda5kQyHOaU4GDmjWZgu {\n  backdrop-filter: invert(1);\n}\n", "",{"version":3,"sources":["webpack://./src/widgets/layout/Layout.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EAEA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,8CAAA;AAAF;AAGA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;EACA,qCAAA;AADF;AAIA;EACE,0BAAA;AAFF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow: hidden;\n\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  background-attachment: fixed;\n  transition: background-image 500ms ease-in-out;\n}\n\n.body {\n  display: flex;\n  position: relative;\n  flex-grow: 1;\n  flex-shrink: 1;\n  z-index: 0;\n  transition: backdrop-filter 0.5s ease;\n}\n\nhtml[data-theme=\"light\"] .body {\n  backdrop-filter: invert(1);\n  // backdrop-filter: brightness(4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "lnaviO6L3CAXT5EFkhIQ",
	"body": "Uda5kQyHOaU4GDmjWZgu"
};
export default ___CSS_LOADER_EXPORT___;
