import { createSlice } from "@reduxjs/toolkit";
import { EventItemType } from "api/ticketsApi";
import { processFetchOrder } from "processes/order/processFetchOrder";

export interface OrderItem {
  amount: number;
  count: number;
  item: EventItemType;
}

export interface OrderType {
  id: number;
  count: number;
  total: number;
  pay: number;
  time: number;
  items: { [id: number]: OrderType };
}

interface StateType {
  orders: {
    [id: number]: {
      pending: boolean;
      data?: OrderType;
    };
  };
}

const initialState: StateType = {
  orders: {},
};

export const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processFetchOrder.pending, (state, action) => {
        state.orders[action.meta.arg] = { pending: true };
      })
      .addCase(processFetchOrder.fulfilled, (state, action) => {
        const data: OrderType = action.payload;
        state.orders[data.id] = {
          pending: false,
          data,
        };
      });
  },
});

export default slice.reducer;
