import { createContext, useState, useEffect } from "react";

export enum Themes {
  dark = "dark",
  light = "light",
}

interface ThemeContextType {
  theme: Themes;
  changeTheme: (theme: Themes) => void;
  toggleTheme: () => void;
}

const defaultTheme = Themes.dark;
// const defaultTheme =
//   localStorage.getItem("theme") ||
//   (window.matchMedia("(prefers-color-scheme: dark)").matches
//     ? themes.dark
//     : themes.light);

if (defaultTheme) {
  document.documentElement.setAttribute("data-theme", defaultTheme);
}

export const ThemeContext = createContext<ThemeContextType>({
  theme: defaultTheme,
  changeTheme: () => {},
  toggleTheme: () => {},
});

export default function ThemeContextWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const [theme, setTheme] = useState(defaultTheme);

  const saveTheme = (theme: Themes) => {
    setTheme(theme);
    localStorage.setItem("theme", theme);
  };

  const changeTheme = (theme: Themes) => saveTheme(theme);
  const toggleTheme = () =>
    saveTheme(theme === Themes.dark ? Themes.light : Themes.dark);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, changeTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
