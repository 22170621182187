import styles from "features/logo/Logo.module.less";

import logoDark from "app/assets/logo_dark.png";
import logoLight from "app/assets/logo_light.png";
import ThemedImage, { ThemedImageSrcType } from "shared/components/ThemedImage";
import { ThemeContext, Themes } from "shared/lib/theme";

const imgs: ThemedImageSrcType = {
  [Themes.dark]: logoDark,
  [Themes.light]: logoLight,
};

function Logo() {
  return (
    <ThemeContext.Consumer>
      {({ theme, toggleTheme }) => (
        <ThemedImage
          srcs={imgs}
          className={styles.logo}
          alt="Логотип"
          onClick={toggleTheme}
        />
      )}
    </ThemeContext.Consumer>
  );
}

export default Logo;
